export default {
  init() {
    // JavaScript to be fired on all pages

    // Toggle class on scroll
    $(document).scroll(function() {
      var value=$(document).scrollTop();

      if ( value >= 180 ) {
        $('header.banner').addClass('scrolled');
      return;
      } else {
        $('header.banner').removeClass('scrolled');
      }
    });

    // Trigger modal.
    // onClick slides the carousel.
    $(document).ready(function(){
        $('.modal-trigger').click(function(){
            $('#carouselModal').modal('show');
        });
    });

    // stop dummy links
    $('a[href="#"]').click(function(e){
      e.preventDefault();
    });

    // Toggle dropdown menus on click
    $('.dropdown-toggle').on('click', function() {
      $(this).next('.dropdown-menu').slideToggle(400);
    });

    // Set offCanvas section
    var offCanvas = document.getElementById('offcanvasNavbar');

    // Show offCanvas
    offCanvas.addEventListener('show.bs.offcanvas', function () {
      $('.hamburger').addClass('is-active');
    });
    // Hide offCanvas
    offCanvas.addEventListener('hide.bs.offcanvas', function () {
      $('.hamburger').removeClass('is-active');
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    function isInViewport(node) {
      // Am I visible? Height and Width are not explicitly necessary in visibility
      // detection, the bottom, right, top and left are the essential checks. If an
      // image is 0x0, it is technically not visible, so it should not be marked as
      // such. That is why either width or height have to be > 0.
      var rect = node.getBoundingClientRect()
      return (
        (rect.height > 0 || rect.width > 0) &&
        rect.bottom >= 0 &&
        rect.right >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    var width = $(window).width();
    $(window).resize(function() {
      width = $(window).width();
    });

    $(window).on('scroll', function () {
      $('.animate__animated.fadeIn').each(function() {
        var initY = $(this).offset().top
        var height = $(this).height()
        //var endY = initY + $(this).height()
        // Check if the element is in the viewport.
        var scrolled = $(document).scrollTop();
        var visible = isInViewport(this)
        if(visible) {
          var diff = scrolled - initY
          var ratio = (diff / height) * 100
          $(this).find('.dummy-item').css('background-position','center CALC(50% + ' + Math.floor((ratio * 3)) + 'px)')
          $(this).addClass('animate__fadeIn');
        }
      });
    });

    $(window).on('scroll', function () {
      if (width >= 768){
        var scrolled = $(document).scrollTop();

        $('.parallax-background').each(function() {
          var initY = $(this).offset().top
          var height = $(this).height()
          //var endY = initY + $(this).height()
          // Check if the element is in the viewport.
          var visible = isInViewport(this)
          if(visible) {
            var diff = scrolled - initY
            var ratio = (diff / height) * 100
            $(this).css('background-position','center CALC(50% + ' + Math.floor((ratio * 3)) + 'px)')
          }
        })

        $('.parallax-video').each(function() {
          var initY = $(this).offset().top
          var height = $(this).height()
          //var endY = initY + $(this).height()
          // Check if the element is in the viewport.
          var visible = isInViewport(this)
          if(visible) {
            var diff = scrolled - initY
            var ratio = (diff / height) * 100
            $(this).css('top','CALC(1px + ' + Math.floor((ratio * 3)) + 'px)')
          }
        })

        $('.parallax-carousel').each(function() {
          var initY = $(this).offset().top
          var height = $(this).height()
          //var endY = initY + $(this).height()
          // Check if the element is in the viewport.
          var visible = isInViewport(this)
          if(visible) {
            var diff = scrolled - initY
            var ratio = (diff / height) * 100
            $(this).find('.carousel-item').css('background-position','center CALC(50% + ' + Math.floor((ratio * 3)) + 'px)')
          }
        })
      }
    });

    // Scroll to enquiry section
    $('.enquiry').each(function(){
      $(this).click(function(){
        $('html,body').animate({scrollTop: $('#enquiry').offset().top -100 }, 'slow');
        return false;
      });
    });

    // Slick Slider settings
    $(document).ready(function(){
      $('#video-slider').slick({
        infinite: true,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '10%',
        dots: true,
        appendArrows: '.arrows',
        appendDots: '.arrows',
        prevArrow: '<span class="prev-arrow slick-prev"><i class="fa-solid fa-chevron-left fa-xl"></i></span>',
        nextArrow: '<span class="next-arrow slick-next"><i class="fa-solid fa-chevron-right fa-xl"></i></span>',
      });
    });
  },
};
